import * as React from "react";
import { logger } from "bernie-core";
import { useQuery } from "@apollo/client";
import { CustomerNotifications } from "components/shared/CustomerNotifications";
import {
  StorefrontWizardRegionTypes,
  StorefrontWizardRegionTypesContext,
} from "components/flexComponents/PageLevelProviders/WizardContextProvider/StorefrontWizardRegionTypesContext";
import { withLocalization } from "@shared-ui/localization-context";
import { LobQueryRouter } from "src/components/shared/StorefrontWizard/LobQueryRouter";
import {
  getNonWizardLOBTemplateComponents,
  getWizardLOBTemplateComponents,
} from "src/components/shared/StorefrontWizard/StorefrontWizardUtils";
import {
  TabbedWizardCard,
  WizardRegionType,
} from "src/components/shared/StorefrontWizard/TabbedWizardCard/TabbedWizardCard";
import { EGDSFigure, EGDSFigureAspectRatioType, EGDSImage } from "@egds/react-core/images";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import { useBexApi } from "@shared-ui/bex-api-context";
import { LobFormWithTabs } from "src/components/shared/StorefrontWizard/LobFormWithTabs/LobFormWithTabs";
import { getTracker } from "src/utils/TrackingUtils";
import { LOB_KEYS } from "components/shared/StorefrontWizard/constants";
import { LOB_NAME_MAP_REF } from "./constants";
import { withStores } from "stores";
import { cropResizeSrc } from "src/components/shared/BlossomImage/BlossomImage";
import { filterLobsFromOverrideList } from "components/flexComponents/WizardPWARegions/StorefrontWizardRegionTAAP/utils";
import {
  LobNameMapRef,
  StorefrontWizardRegionTAAPProps,
  StorefrontWizardRegionTAAPTemplateComponentConfig,
} from "components/flexComponents/WizardPWARegions/StorefrontWizardRegionTAAP/typings";
import { SystemEvent, SystemEventLevel } from "bernie-logger";
import * as TAAP_SITES from "./taapSitesConfig";
import { AuthenticationState } from "bernie-context";

import {
  TravelAgentSearchWizardConfigQueryDocument,
  TravelAgentSearchWizardConfigQueryQueryVariables,
} from "__generated__/typedefs";
import { RegionChildrenList } from "src/utils/RegionUtils";

const StorefrontWizardRegionTAAPModule = (props: StorefrontWizardRegionTAAPProps) => {
  const { templateComponent, flexModuleModelStore, blossomComponent, context, wizardState, analytics } = props;
  const formChildrenTemplateComponents = getWizardLOBTemplateComponents(templateComponent.children || []);
  let filteredFormChildrenTemplateComponent = formChildrenTemplateComponents;

  const { context: bexApiContext } = useBexApi();
  const travelAgentQueryVariables: TravelAgentSearchWizardConfigQueryQueryVariables = {
    context: bexApiContext,
  };

  const { data, error } = useQuery(TravelAgentSearchWizardConfigQueryDocument, {
    variables: travelAgentQueryVariables,
    skip:
      context.user.authState !== AuthenticationState.AUTHENTICATED ||
      !(
        context.site.id === TAAP_SITES.taapSitesConfig.unitedStatesSiteId ||
        context.site.id === TAAP_SITES.taapSitesConfig.unitedStatesNewSiteId
      ),
  });

  if (error) {
    props.logger.logEvent(
      new SystemEvent(SystemEventLevel.WARN, "FAILED_TRAVEL_AGENT_FETCH"),
      "Failed to retrieve travel agent configuration",
      error.message
    );
  }

  if (data) {
    const lobListForUser: string[] | undefined = data.travelAgentSearchWizardConfig?.enabledLobs;

    if (lobListForUser && lobListForUser.length > 0 && lobListForUser.length < 6) {
      filteredFormChildrenTemplateComponent = filterLobsFromOverrideList(
        formChildrenTemplateComponents,
        lobListForUser,
        flexModuleModelStore
      );
    }
  }

  if (!blossomComponent || !templateComponent.children || templateComponent.children.length < 1) {
    return null;
  }

  const options = templateComponent.config as StorefrontWizardRegionTAAPTemplateComponentConfig;

  //default to false if device information is not available
  wizardState.globalWizardState.setIsDesktop(context?.deviceInformation);
  const setDefaultLob = (defaultLob: string = LOB_KEYS.HOTEL_V2) => {
    if (defaultLob) {
      wizardState.globalWizardState.config.navigation.defaultLob = defaultLob;
      wizardState.globalWizardState.config.navigation.activeLob = defaultLob;
    }
  };

  const isSingleLOB = filteredFormChildrenTemplateComponent?.length === 1;
  const otherFormChildren = getNonWizardLOBTemplateComponents(templateComponent.children);

  const heroImageSrc = options.backgroundImage ?? wizardState.globalWizardState.config.heroImageSrc;
  const defaultHeroImageSrc =
    "https://a.travel-assets.com/travel-assets-manager/cmct-2135/CA_Autumn_Canmore_1400x600.jpg";
  const imageSrcTemp = cropResizeSrc(heroImageSrc || defaultHeroImageSrc, { width: 1400, height: 600 }, "medium");

  const isStorefrontPage = context?.searchContext?.pageType.indexOf("Storefront") > -1;
  const wizardRegionClassNames = "StorefrontWizardRegionTAAP";

  const trackOnChangeTab = (tabName: string, rfrrId?: string) => {
    const moduleName = templateComponent.type || "SFWizardTAAP";
    const tracker = getTracker(wizardState?.canTrack, analytics, moduleName);
    const rfrr = `LOB_Selection_Occured${LOB_NAME_MAP_REF[tabName as keyof LobNameMapRef] ?? ""}`;
    tracker.trackEvent(`${rfrr}${rfrrId ?? ""}`);
  };

  setDefaultLob(options.defaultLOB);

  /**
   *
   * Note for modules below this region:
   * Modules not part of this region do not align to the form content out of the box
   * Setting the flex manager attribute brandModulePadding to taap_padding puts a spacing__twelve on the components below this region
   * This aligns them with the form content within the card on this wizard
   * On mobile, there is no card, and this same attribute puts spacing__six on these components
   */
  return (
    <StorefrontWizardRegionTypesContext.Provider value={StorefrontWizardRegionTypes.STOREFRONT_WIZARD_REGION_TAAP}>
      <div className={wizardRegionClassNames} data-testid="storefront-region-taap">
        <div data-testid="main-region">
          <Viewport>
            <ViewSmall>
              <>
                <LobQueryRouter lobsTemplateComponent={filteredFormChildrenTemplateComponent} />
                {isStorefrontPage && (
                  <CustomerNotifications location="AFTER_HEADER" className="customerNotificationSquare" />
                )}
                {isSingleLOB ? (
                  <EGDSSpacing padding={{ inline: "six", blockend: "six" }}>
                    <div data-testid="single-lob">
                      <RegionChildrenList
                        templateComponents={filteredFormChildrenTemplateComponent}
                        blossomComponent={blossomComponent}
                      />
                    </div>
                  </EGDSSpacing>
                ) : (
                  <LobFormWithTabs
                    context={context}
                    templateComponents={filteredFormChildrenTemplateComponent}
                    blossomComponent={blossomComponent}
                    wizardFormSpacing={{ padding: { inline: "six", blockend: "six" } }}
                    trackOnChangeTab={trackOnChangeTab}
                  />
                )}
              </>
            </ViewSmall>

            <ViewMedium>
              <EGDSFigure className="taapHeroImageFigure noContentVisibility" ratio={EGDSFigureAspectRatioType.R4_1}>
                <EGDSImage alt="" src={imageSrcTemp} className="taapHeroImage" />
                <LobQueryRouter lobsTemplateComponent={filteredFormChildrenTemplateComponent} />
                {isStorefrontPage && (
                  <EGDSSpacing padding={{ inline: "six", blockstart: "six" }}>
                    <CustomerNotifications location="AFTER_HEADER" className="customerNotificationSquare" />
                  </EGDSSpacing>
                )}
                <TabbedWizardCard
                  context={context}
                  wizardFormChildren={filteredFormChildrenTemplateComponent}
                  wizardState={wizardState}
                  wrappingClassNames="SimpleContainer"
                  wrappingSpacing={{ padding: { inline: "six", blockstart: "twelve" } }}
                  tabGroupClassNames="formAlignedTabs"
                  wizardFormClassNames="wizardOverHeroImage"
                  wizardFormSpacing={{ padding: { inline: "six" } }}
                  trackOnChangeTab={trackOnChangeTab}
                  wizardRegionType={WizardRegionType.TAAP}
                  blossomComponent={blossomComponent}
                />
              </EGDSFigure>
            </ViewMedium>
          </Viewport>
          {otherFormChildren}
        </div>
      </div>
    </StorefrontWizardRegionTypesContext.Provider>
  );
};

export const StorefrontWizardRegionTAAP = withLocalization(
  logger("StorefrontWizardRegionTAAP")(
    withStores(
      "wizardState",
      "compositionStore",
      "analytics",
      "flexModuleModelStore",
      "context"
    )(StorefrontWizardRegionTAAPModule)
  )
);

export default StorefrontWizardRegionTAAP;
